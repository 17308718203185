<template>
  <div>
    <span class="advanced-query-title" @click="handleToLink()" style="cursor: pointer;">高级查询</span>
  </div>
</template>

<script>
export default {
  name: 'link-to-advanced',
  data () {
    return {
    }
  },
  methods: {
    handleToLink () {
      this.$router.push({
        name: 'high-query-conditions'
      })
    }
  }
}
</script>

<style>
</style>
