<template>
  <!-- 查技术人员 -->
  <div style="margin-top: 120px;">
    <search-nav :active="4"></search-nav>
    <!-- <el-affix :offset="120"> -->
      <el-row>
        <div class="container">
          <!--输入框-->
          <el-row style="margin-bottom: 30px">
            <el-col :span="13">
              <div>
                <el-autocomplete
                  style="width: 100%"
                  clearable
                  class="inline-input"
                  :fetch-suggestions="querySearch"
                  v-model="conditions.keyword"
                  placeholder="请输入技术人员关键词"
                  @select="handleSelect"
                  :trigger-on-focus="false"
                  size="medium"
                  input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px"
                >
                </el-autocomplete>
              </div>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" style="background: #FC7A1E;border-color: #FC7A1E;" class="search-btn-search" @click="handleSearchList()" size="medium">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    <!-- </el-affix> -->
    <el-row>
      <div class="container">
        <!--筛选条件-->
        <div style="margin-bottom: 30px" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">筛选条件</span>
            <link-to-advanced></link-to-advanced>
          </div>
          <div style="padding: 10px 0 20px 0;">
          <el-row v-if="tags.length > 0" class="dis-cen-sta">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #5D6FE9;height: 40px;line-height: 40px;">已选条件：</div></el-col>
            <el-col :span="20" class="dis-sta-cen" style="margin-top: 5px;">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px" v-for="(tag, index) in tags" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAllTag" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.provinceModel.name" class="dis-cen-sta">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;" >企业地区：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioProvinceValueArr" :defaultShowNum="arrangementRadioProvinceDefaultShowNum" @handleChange="arrangementRadioProvinceChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.cityModel.name && conditions.provinceModel.name && conditions.provinceModel.name != '北京市' && conditions.provinceModel.name != '天津市' && conditions.provinceModel.name != '上海市' && conditions.provinceModel.name != '重庆市'" class="dis-cen-sta">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">城市：</div></el-col>
            <el-col :span="22">
              <div class="grid-content bg-purple">
                <arrangement-radio :valueArr="arrangementRadioCityValueArr" :defaultShowNum="arrangementRadioCityDefaultShowNum" @handleChange="arrangementRadioCityChange"></arrangement-radio>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.relevancyIdArr" class="dis-cen-cen already-choose">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">人员分类：</div></el-col>
            <el-col :span="22">
              <el-row>
                <el-col :span="13" >
                  <div v-if="clearForm">
                    <el-cascader
                      ref="cascader"
                      clearable
                      collapse-tags
                      separator=">"
                      filterable="true"
                      placeholder="请选择人员分类"
                      style="width: 100%;"
                      v-model="cascaderValue"
                      size="medium"
                      :options="cascaderOptions"
                      :props="cascaderProps"
                      @change="cascaderHandleChange"></el-cascader>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-if="!conditions.companyKeyword" class="dis-cen-cen already-choose">
            <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">企业名称：</div></el-col>
            <el-col :span="22">
              <el-row class="dis-sta-cen">
                <el-col :span="13">
                  <div>
                    <el-input v-model="companyKeyword" placeholder="请输入企业名称关键词" size="medium" clearable></el-input>
                  </div>
                </el-col>
                <el-col :span="4" style="float: left; margin: 0px 0px 0px 10px;">
                  <el-button type="" style="float: left"  size="medium" @click="companyKeywordClick">确定</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="dis-cen-cen already-choose">
            <el-col :span="2">
              <div class="grid-content bg-purple" >
                <el-button type="primary" @click="queryList" size="medium">查询</el-button>
              </div>
            </el-col>
          </el-row>
          </div>
        </div>
        <!--内容卡片 柏成大佬-->
        <el-row style="margin-top: 30px;margin-bottom: 30px">
          <el-col :span="24">
            <div class="condition-content">
              <div class="dis-bet-cen condition-title">
                <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total }}</span> 条符合条件的人员</span>
                <span class="advanced-query-title">
                <el-select v-model="optionsValue" @change="handleSelsect" placeholder="默认排序">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </span>
              </div>
              <div class="condition-content-list">
                <block v-show="!fuzzyDataShow" v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover" @click="companyNameClick(item, index)">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-bet-cen">
                        <div class="dis-sta-cen">
                          <span v-html="(item.companyName ? item.companyName + '-' : '') + item.name"></span>
                          <span class="focus-enterprises-tag-yellow">{{item.parentQualificationName}}</span>
                        </div>
                        <span v-if="item.isfocus === 4">
                      <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                        <span  class="focus-enterprises-tag-yellow">未核验</span>
                      </el-tooltip>
                    </span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="5">岗位类别：<span class="font-color-gray">{{item.registeredType || '-'}}</span></el-col>
                        <el-col :span="7">岗位证书编号：<span class="font-color-gray">{{item.certificateno || '-'}}</span></el-col>
                        <el-col :span="5">发证时间：<span class="font-color-gray">{{item.registeredTime || '-'}}</span></el-col>
                        <el-col :span="5">有效期至：<span class="font-color-gray">{{item.validPeriod || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
                <gaussian-blur pageName="company-achievement" v-show="fuzzyDataShow"></gaussian-blur>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--分页组件-->
        <div class="dis-end-sta" style="margin-bottom: 30px">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            v-model:currentPage="page.pageNum"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import ArrangementRadio from '@/components/query-conditions/arrangement-radio' // 省市的平铺单选
import { ElMessage } from 'element-plus'
import SearchNav from '@/components/search-nav/search-nav'
import LinkToAdvanced from '@/components/link-to-advanced/link-to-advanced'
import provinceData from '@/assets/pca-code.js'
import { getAllArtisanRelevancy } from '@/api/query.js'
import { forwardBG, oldForwardBG } from '@/api/forward.js'
import { mapGetters } from 'vuex'
import GaussianBlur from '@/components/gaussian-blur/gaussian-blur'
export default {
  name: 'company-registered-personnel',
  components: {
    ArrangementRadio,
    SearchNav,
    LinkToAdvanced,
    GaussianBlur
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      oldKeyword: '',
      provinceData: provinceData,
      companyKeyword: '', // 企业名称关键字
      conditions: {
        keyword: this.$route.query.searchVal ? this.$route.query.searchVal : '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        companyKeyword: '', // 已经选择的企业名称
        relevancyIdArr: null // 人员分类多级数组
      },
      queryConditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        companyKeyword: '', // 已经选择的企业名称
        relevancyIdArr: null, // 人员分类多级数组
        orderType: ''
      },
      arrangementRadioProvinceValueArr: [], // 平铺单选的组件数据 省
      arrangementRadioProvinceDefaultShowNum: 12, // 平铺单选的默认显示数量 省
      arrangementRadioCityValueArr: [], // 平铺单选的组件数据 市
      arrangementRadioCityDefaultShowNum: 10, // 平铺单选的默认显示数量 市
      clearForm: true,
      cascaderValue: [],
      totalCascaderList: [],
      cascaderOptions: [], // 需要的格式参考饿了么UI 的级联选择器
      cascaderProps: { multiple: false, expandTrigger: 'hover' },
      // 数据
      dataList: [],
      // 排序的筛选
      optionsValue: '0',
      options: [{
        value: '0',
        label: '默认排序',
        orderType: ''
      }, {
        value: '1',
        label: '发证时间升序',
        orderType: 'asc'
      }, {
        value: '2',
        label: '发证时间降序',
        orderType: 'desc'
      }],
      tags: [],
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1
      },
      returnLimit: false, // 返回数据是否受限
      fuzzyDataShow: false, // 模糊图片是否显示
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken'
    ]),
    vipLevelChange: function () {
      const vm = this
      // console.log('计算属性: ' + JSON.parse(vm.vipData).vipLevel)
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  watch: {
    vipLevelChange: function (newValue, oldValue) {
      const vm = this
      // 初始化 省市数据
      vm.initArrangementRadioValueArr()
      vm.handleSearchList()
    }
  },
  mounted () {
    const vm = this
    // 初始化 省市数据
    vm.initArrangementRadioValueArr()
    vm.handleSearchList()
  },
  methods: {
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    },
    // 获取所有人员分类
    initArrangementRadioValueArr () {
      const vm = this
      vm.arrangementRadioProvinceValueArr = vm.provinceData.pcaData
      getAllArtisanRelevancy().then(result => {
        console.log(result)
        if (result.code === 0) {
          const res = result.data
          vm.totalCascaderList = res
          vm.getRelevancyList(res, '0', function (a) {
            console.log(a)
            vm.cascaderOptions = a
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getRelevancyList: function (data, parentId, callback) {
      const arr = []
      const vm = this
      data.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          obj = {
            value: item.id,
            label: item.name,
            parentId: item.parentId
          }
          if (vm.getRelevancyListChild(data, item, item.id).length > 0) {
            obj.children = vm.getRelevancyListChild(data, item, item.id)
          }
          arr.push(obj)
        }
      })
      callback(arr)
    },
    getRelevancyListChild: function (arr, parentItem, parentId) {
      const vm = this
      const arrs = []
      arr.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          if (vm.getRelevancyListChild(arr, item, item.id).length > 0) {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId,
              children: vm.getRelevancyListChild(arr, item, item.id)
            }
          } else {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId
            }
          }
          arrs.push(obj)
        }
      })
      return arrs
    },
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      const arr = []
      callback(arr)
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
      console.log(item)
    },
    // 选则排序触发
    handleSelsect: function (e) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          // 去查询
          vm.page.pageNum = 1
          vm.queryConditions.orderType = vm.options[e].orderType
          vm.getList()
        }
      } else {
        vm.optionsValue = '0'
        vm.loginEmit()
      }
    },
    // 省市组件选择完了的回调 省
    arrangementRadioProvinceChange (item) {
      const vm = this
      vm.arrangementRadioCityValueArr = item.children
      vm.conditions.provinceModel = item
      vm.tags.push({
        name: '省份地区：' + item.name,
        value: 'provinceModel',
        default: {}
      })
    },
    // 省市组件选择完了的回调 市
    arrangementRadioCityChange (item) {
      const vm = this
      vm.conditions.cityModel = item
      vm.tags.push({
        name: '城市：' + item.name,
        value: 'cityModel',
        default: {}
      })
    },
    // 企业名称的确定按钮
    companyKeywordClick () {
      const vm = this
      if (vm.companyKeyword) {
        vm.conditions.companyKeyword = vm.companyKeyword
        vm.companyKeyword = ''
        vm.tags.push({
          name: '企业名称：' + vm.conditions.companyKeyword,
          value: 'companyKeyword',
          default: ''
        })
      } else {
        return ElMessage.warning({
          message: '请先输入企业名称关键字',
          type: 'warning',
          offset: 60
        })
      }
    },
    // 多级下拉的回调
    cascaderHandleChange (item) {
      const vm = this
      console.log(item)
      const n = item.length - 1
      for (let i = 0; i < vm.totalCascaderList.length; i++) {
        if (vm.totalCascaderList[i].id === item[n]) {
          const obj = {
            name: vm.totalCascaderList[i].name,
            id: item[n]
          }
          vm.conditions.relevancyIdArr = obj
          break
        }
      }
      const relevancyNameArr = vm.treeToLabel(vm.cascaderOptions, item)
      // 清理选中项
      vm.cascaderValue = []
      vm.tags.push({
        name: '人员分类：' + relevancyNameArr,
        value: 'relevancyIdArr',
        default: null
      })
    },
    // 关闭Tag触发
    closeTag (item, index) {
      const vm = this
      vm.tags.splice(index, 1)
      vm.conditions[item.value] = item.default
      if (item.value === 'provinceModel') {
        vm.conditions.cityModel = {}
        for (let i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].value === 'cityModel') {
            return vm.closeTag(vm.tags[i], i)
          }
        }
      }
    },
    // 清除全部Tag
    closeAllTag () {
      const vm = this
      vm.tags = []
      vm.conditions.provinceModel = {}
      vm.conditions.cityModel = {}
      vm.conditions.companyKeyword = ''
      vm.conditions.relevancyIdArr = null
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          document.documentElement.scrollTop = 0
          vm.page.pageNum = cuttentPage
          vm.getList()
        }
      } else {
        vm.page.pageNum = -Math.ceil(Math.random() * 1000)
        vm.loginEmit()
      }
    },
    treeToLabel (data, arr) {
      const result = []
      const childrenTreeToLabel = (data, arr, result) => {
        for (let j = 0; j < data.length; j++) {
          if (arr[0] === data[j].value) {
            result.push(data[j].label)
            if (data[j].children && arr.length > 1) {
              childrenTreeToLabel(data[j].children, arr.slice(1), result)
            }
            break
          }
        }
      }
      childrenTreeToLabel(data, arr, result)
      return result.join('>')
    },
    // 点击查询触发
    handleSearchList: function () {
      const vm = this
      // 如果跟上次查询的不一样就清理下面
      if (vm.oldKeyword !== vm.conditions.keyword) {
        vm.closeAllTag()
        vm.queryConditions.provinceModel = {}
        vm.queryConditions.cityModel = {}
        vm.queryConditions.companyKeyword = ''
        vm.queryConditions.relevancyIdArr = null
      }
      vm.oldKeyword = vm.conditions.keyword
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      // 去查询
      vm.getList()
    },
    queryList: function () {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          vm.page.pageNum = 1
          vm.queryConditions.provinceModel = vm.conditions.provinceModel
          vm.queryConditions.cityModel = vm.conditions.cityModel
          vm.queryConditions.relevancyIdArr = vm.conditions.relevancyIdArr
          vm.queryConditions.companyKeyword = vm.conditions.companyKeyword
          vm.getList()
        }
      } else {
        vm.loginEmit()
      }
    },
    getList: function () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        dateBackgroundUrl: vm.idToken ? '/esPersonnel/getESPersonnelListByModel' : '/esFake/esPersonnel/getESPersonnelListByModel',
        dateBackgroundRequestType: 'GET',
        data: {
          province: vm.queryConditions.provinceModel ? vm.queryConditions.provinceModel.name : null,
          city: vm.queryConditions.cityModel ? vm.queryConditions.cityModel.name : null,
          registeredType: vm.queryConditions.relevancyIdArr ? vm.queryConditions.relevancyIdArr.name : null,
          subRelevancyId: vm.queryConditions.relevancyIdArr ? vm.queryConditions.relevancyIdArr.id : null,
          name: vm.queryConditions.keyword ? vm.queryConditions.keyword.trim() : null,
          companyName: vm.queryConditions.companyKeyword ? vm.queryConditions.companyKeyword.trim() : null,
          orderType: vm.queryConditions.orderType === '' ? null : vm.queryConditions.orderType,
          orderField: vm.queryConditions.orderType === '' ? null : 'registered_time',
          pageNum: vm.page.pageNum,
          pageSize: 10
        }
      }
      const forward = vm.idToken ? oldForwardBG : forwardBG
      forward(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const res = result.data
          console.log(res)
          const arr = []
          if (res.length > 0) {
            res.forEach((item, index) => {
              vm.page.total = item.dataTotal
              const obj = {
                companyId: item.companyId,
                companyName: item.companyName,
                validPeriod: item.validPeriod === undefined || item.validPeriod === null || item.validPeriod === 'null' || item.validPeriod === '' ? '-' : (item.validPeriod).substring(0, 10),
                registeredTime: item.registeredTime === null || item.registeredTime === 'null' || item.registeredTime === '' ? '-' : (item.registeredTime).substring(0, 10),
                qualificationName: item.qualificationName ? item.qualificationName : '-',
                name: item.name === null || item.name === 'null' ? '-' : item.name,
                registeredType: item.registeredType === null || item.registeredType === 'null' ? '-' : item.registeredType,
                certificateno: item.certificateno === null || item.certificateno === 'null' ? '-' : item.certificateno,
                parentQualificationName: item.parentQualificationName === null || item.parentQualificationName === 'null' ? '-' : item.parentQualificationName
              }
              arr.push(obj)
            })
          } else {
            vm.page.total = 0
          }
          vm.dataList = arr
          loading.close()
        } else {
          loading.close()
        }
      }).catch(error => {
        console.log(error)
        loading.close()
      })
    }
  }
}
</script>

<style scoped="scoped">
.already-choose {
  margin-top: 15px;
}
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
.el-divider--horizontal {
  margin: 0;
}
/deep/ .el-input.el-input--medium.el-input--suffix {
  line-height: 50px !important;
}
</style>
