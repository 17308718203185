<template>
  <div style="background-color: #fff;position: relative;width: 100%;z-index: 8;">
    <el-row style="position: fixed;top: 60px;width: 100%;background-color: #fff;">
      <el-col :span="24" class="border-bottom">
        <div class="container" style="position: relative;">
         <ul class="dis-aro-cen">
           <li class="nav-btn" :class="{active:index == btnActive}" v-for="(item, index) of navButtonList" :key="index" @click="handleClick(index, item)">{{item.name}}</li>
         </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'tabulate-data',
  props: ['active'],
  data () {
    return {
      btnActive: this.active,
      navButtonList: [
        {
          name: '查企业',
          url: 'company',
          introduce: '请输入企业名称关键词',
          active: '1'
        },
        {
          name: '查资质',
          url: 'company-credential',
          introduce: '请输入资质名称关键词',
          active: '2'
        },
        {
          name: '查中标',
          url: 'company-achievement',
          introduce: '请输入中标项目关键词',
          active: '3'
        },
        {
          name: '查荣誉',
          url: 'company-honor-image',
          introduce: '请输入荣誉证书关键词',
          active: '4'
        },
        {
          name: '查人员',
          url: 'company-registered-personnel',
          introduce: '请输入人员名称关键词',
          active: '5'
        },
        {
          name: '查业绩',
          url: 'company-personnel-perfo',
          introduce: '请输入企业名称关键词',
          active: '6'
        },
        {
          name: '知识产权',
          url: 'company-intellectual-property',
          introduce: '',
          active: '7'
        }
      ]
    }
  },
  methods: {
    handleClick: function (e, item) {
      this.btnActive = e
      this.$router.push({
        name: item.url
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.border-bottom {
  border-top: 1px solid #E1E2E6;
}
.border-bottom::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #E4E7ED;
  z-index: 1;
}
.nav-btn {
  position: relative;
  height: 48px;
  line-height: 48px;
  list-style: none;
  font-size: 16px;
  color: #666;
  cursor: pointer;
}
.nav-btn.active::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  z-index: 2;
  margin-bottom: 1px;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #5D6FE9;
}
</style>
