<template>
  <el-row>
    <el-col :span="22" class="dis-sta-cen" style="flex-wrap: wrap;">
      <v-for v-for="(item, index) in valueArr" :key="index">
        <el-button v-show="index < defaultShowNum || showAll" class="elButtonShop" type="text" @click="buttonListClick(item)"><p class="hover-class">{{ item.name }}</p></el-button>
      </v-for>
    </el-col>
    <el-col :span="2">
      <el-button v-show="valueArr.length > defaultShowNum" class="scaling" type="text" @click="switchClick">
        <p v-if="showAll">收起<i class="iconfont">&#xe63f;</i></p>
        <p v-if="!showAll">更多<i class="iconfont">&#xe8bc;</i></p>
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import provinceData from '@/assets/pca-code.js'
// 排列单选
// 超过一行显示更多
export default {
  name: 'arrangement-radio',
  props: {
    // 内容示例
    // key: '吉林' + i, // 显示的值
    // value: i.toString(), // 实际的值
    // tag: i.toString() // 为了带其他东西冗余
    valueArr: {
      type: Array,
      default: () => []
    },
    // 默认显示数量
    defaultShowNum: {
      type: Number,
      default: () => 5
    }
  },
  data () {
    return {
      provinceData: provinceData,
      // thisDefaultShowNum: 0
      showAll: false
    }
  },
  // watch: {
  //   defaultShowNum (newVal) {
  //     console.log('值变化')
  //     this.thisDefaultShowNum = newVal
  //   }
  // },
  created () {
  },
  methods: {
    // 按钮列表的点击
    buttonListClick (item) {
      // console.log(JSON.stringify(item))
      this.$emit('handleChange', item) // 触发父组件中handleChange事件并传参Jack
    },
    // 点击更多或者收起
    switchClick () {
      const vm = this
      vm.showAll = !vm.showAll
    }
  }
}
</script>

<style scoped>
.elButtonShop{
  margin-right: 30px;
}
.elButtonShop p {
  font-size: 14px;
  color: #666666;
}
.scaling p{
  float: right;
  font-size: 14px;
  color: #666666;
}
.hover-class:hover {
  color: rgba(93, 111, 233, 1)
}
</style>
